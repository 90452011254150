import React, { useState, useEffect } from 'react'
import '../css/contact.css'
import Header from '../components/Header'
import ContactForm from '../components/ContactForm'
import CopyrightText from '../components/CopyrightText'

function Contact () {
    const [theme, setTheme] = useState('theme-light');
    var date = new Date();
    var timestamp = Date.parse(date);
    var timeOfDay = date.getHours(parseInt(timestamp));

    // set theme 
    useEffect(() => {
        if (timeOfDay >= 18 || timeOfDay <= 6) {
            setTheme('theme-dark')
        } else {
            setTheme('theme-light')
        }
    }, [timeOfDay])

    return (
        <div className={`contact-wrapper ${theme}`}>
            <Header dark={true} />
            <ContactForm />
            <CopyrightText />
        </div>
    )
}

export default Contact;