import React, { useState, useEffect } from 'react';
import AOS from 'aos'
import '../css/footer.css';
import kc from '../images/footer/Kineto.png';
import hc from '../images/footer/heritageCity.png';
import emperor from '../images/footer/emperor.png';
import gwi from '../images/footer/GWI.png';
import skyview from '../images/footer/skyview.png';
import gtesc from '../images/footer/GTESC1.png';
import ar from '../images/footer/AR.png';
import imperial from '../images/footer/IPC.png';
import ar_right from '../images/footer/AR_new.png';
import kc_right from '../images/footer/KinetoFNL.png';
import skyview_right from '../images/footer/SKYVIEW_New.png'
import gtesc_right from '../images/footer/GTESC_New.png'
import gwi_right from '../images/footer/GWI1.png'
import emperor_right from '../images/footer/EmperorFNL.png'
import hc_right from '../images/footer/heritage_city.png'
import imperial_right from '../images/footer/IPC2.png'



function Footer () {
    AOS.init({duration: 1200});

    // initial details to be displayed
    const initialDetails = {
        email: 'info@arsecuritysolutionslimited.com',
        contact: 'https://www.arsecuritysolutionslimited.com',
        title: 'World-Class Security Solutions',
        content: 'A disruptive security solutions providers using modern innovative technology.',
        bg_class: 'bg-ar',
        btn_text: 'SPEAK TO US',
        brand_logo: ar,
        brand_right: ar_right,
        brand_right_class: 'ar_right',
        index: '01'
    }

    // use function state to manage rendering of the set details
    const [details, setDetails] = useState(initialDetails)

    // scale up the subsidiary name
    function scaleUp(e) {
        let x = document.getElementsByClassName('brand-wrapper') // get all the elements with class "brand-wrapper"
        let l = x.length
        
        // loop through the elements
        while(l--) {
            if(e.target.id === x[l].id) {
                e.target.classList.add('scale-up') // add class "scale-up" to the clicked subsidiary name
            } else if (x[l].classList.contains('scale-up')) {
                x[l].classList.remove('scale-up') // remove class "scale-up" from every other brand name
            }
        }
        
    }

    // executes the containing code immediately the page loads
    useEffect(() => {
        [kc, kc_right, hc, hc_right, emperor, emperor_right, gtesc, gtesc_right, gwi, gwi_right, imperial, imperial_right, skyview, skyview_right, ar, ar_right].forEach((picture) => {
            const img = new Image();
            img.src = picture;
            window[picture] = img;
        }); // force browser to prefetch required images

        const el2 = document.querySelector('#brand-right') // select element with id "brand-right"
        if(window.innerWidth > 1080) {
            document.addEventListener('mousemove', (e) => {
                el2.style.left = -(e.clientX/500) + "rem"
                el2.style.top = -(e.clientY/500) + "rem"
            }) // moves footer mock-up image slightly in the opposite direction of the mouse motion
        }
        
    })
    
    
    return (
        <div id='footer' className={`footer-wrapper ${details.bg_class}`} >
            <h1 className='brand-index'>{details.index}</h1>
            <div className='container footer-container'>
                <div id='footer-content-left' className='footer-content-left'>
                    <h3 className='slide-in'>{details.title}</h3>
                    <p className='slide-in'>{details.content}</p>
                    <br />
                    {details.btn_text && <a href={details.contact} target='blank' rel='no referer'><button className='view-gallery'>{details.btn_text}</button></a>}
                </div>

                {/* list of subsidiaries */}
                <div className='footer-content-right'>
                    <div id='ar' className='brand-wrapper scale-up' onClick={(e) => {
                        scaleUp(e)
                        setDetails({
                        email: 'info@arsecuritysolutionslimited.com',
                        contact: 'https://www.arsecuritysolutionslimited.com/contact-us/',
                        title: 'World-Class Security Solutions',
                        content: 'A disruptive security solutions providers using modern innovative technology.',
                        bg_class: 'bg-ar',
                        btn_text: 'SPEAK TO US',
                        brand_logo: ar,
                        brand_right: ar_right,
                        brand_right_class: 'ar_right',
                        index: '01'
                    })}}>
                        <p data-aos='zoom-in' data-aos-anchor='#emperor' data-aos-anchor-placement='bottom'>AR Security Solutions Limited</p>
                    </div>
                    <div id='emperor' className='brand-wrapper' onClick={(e) => {
                        scaleUp(e)
                        setDetails({
                        email: 'info@emperorfarms.com',
                        contact: 'https://www.emperorfarms.com/home/gallery/',
                        title: 'Sustained Freshness at All Times',
                        content: 'A core farming experience that aspires to disrupt the agricultural industry with their 24/7 sustained fresh products while reducing costs and providing quality.',
                        bg_class: 'bg-emperor',
                        btn_text: 'TAKE A TOUR',
                        brand_logo: emperor,
                        brand_right: emperor_right,
                        brand_right_class: 'emperor_right',
                        index: '02'
                    })}}>
                        <p id='emperor' data-aos='zoom-in' data-aos-anchor='#gtesc' data-aos-anchor-placement='bottom'>Emperor Integrated Farms and Projects Limited</p>
                    </div>
                    <div id='gtesc' className='brand-wrapper' onClick={(e) => {
                        scaleUp(e)
                        setDetails({
                        email: 'info@gtesc.net',
                        contact: 'https://www.gtesc.net/projects.php',
                        title: 'Industry Standard Technical Services',
                        content: 'An integrated construction services company providing comprehensive services to an ever-expanding marketplace.',
                        bg_class: 'bg-gtesc',
                        btn_text: 'VIEW PROJECTS',
                        brand_logo: gtesc,
                        brand_right: gtesc_right,
                        brand_right_class: 'gtesc_right',
                        index: '03'
                    })}}>
                        <p id='gtesc' data-aos='zoom-in' data-aos-anchor='#gwi' data-aos-anchor-placement='bottom' >General Technical Engineering Service Company Limited</p>
                    </div>
                    <div id='gwi' className='brand-wrapper' onClick={(e) => {
                        scaleUp(e)
                        setDetails({
                        email: '',
                        contact: 'https://www.gwi-mining.com',
                        title: 'Mining & Engineering',
                        content: ' A dependable service provider in the solid mineral industry of Nigeria capitalizing on a multitude of technical and managerial expertise to provide efficient services.',
                        bg_class: 'bg-gwi',
                        btn_text: 'CALL NOW',
                        brand_logo: gwi,
                        brand_right: gwi_right,
                        brand_right_class: 'gwi_right',
                        brand_logo_class: 'gwi',
                        index: '04'
                    })
                    }}>
                        <p id='gwi' data-aos='zoom-in' data-aos-anchor='#heritage' data-aos-anchor-placement='bottom' >GWI Metallurgy and Engineering</p>
                    </div>
                    <div id='heritage' className='brand-wrapper' onClick={(e) => {
                        scaleUp(e)
                        setDetails({
                        email: 'info@heritagecity.com',
                        contact: 'https://geonelholdings.com/contact-us',
                        title: 'Style and Comfort',
                        content: 'A Pioneer in the creation of the most beautifully planned cities in Nigeria of lush green savannah whilst retaining broad African influences of luxury, serenity and security.',
                        bg_class: 'bg-hc',
                        btn_text: 'VISIT GALLERY',
                        brand_logo: hc,
                        brand_right: hc_right,
                        brand_right_class: 'hc_right',
                        index: '05'
                    })}}>
                        <p id='heritage' data-aos='zoom-in' data-aos-anchor='#imperial' data-aos-anchor-placement='bottom'>Heritage City Development Limited</p>
                    </div>
                    <div id='imperial' className='brand-wrapper' onClick={(e) => {
                        scaleUp(e)
                        setDetails({
                        email: 'info@imperialphoenixconsulting.com',
                        contact: 'https://imperialphoenixconsulting.com/services-2/',
                        title: 'Your Strategic HR Partner',
                        content: 'A hub of multidisciplinary teams of Human Resource specialists proffering tailored professional HR solutions',
                        bg_class: 'bg-imperial',
                        btn_text: 'SCHEDULE A BOOKING',
                        brand_logo: imperial,
                        brand_right: imperial_right,
                        brand_right_class: 'imperial_right',
                        index: '06'
                    })}}>
                        <p id='imperial' data-aos='zoom-in' data-aos-anchor='#kineto' data-aos-anchor-placement='bottom'>Imperial Phoenix Consulting Limited</p>
                    </div>
                    <div id='kc' className='brand-wrapper' onClick={(e) => {
                        scaleUp(e)
                        setDetails({
                        email: 'info@kinetoconsults.com',
                        contact: 'https://kinetoconsults.com/services/',
                        title: 'Elegant And Innovative Design Solutions',
                        content: 'A consortium of experienced and innovative Architects, Designers and professionals providing sustainability in the building industry. ',
                        bg_class: 'bg-kc',
                        btn_text: 'CALL NOW',
                        brand_logo: kc,
                        brand_right: kc_right,
                        brand_right_class: 'kc_right',
                        index: '07'
                    })}}>
                        <p id='kineto' data-aos='zoom-in' data-aos-anchor='#skyview' data-aos-anchor-placement='bottom'>Kineto Consults</p>
                    </div>
                    <div id='skyview' className='brand-wrapper' onClick={(e) => {
                        scaleUp(e)
                        setDetails({
                        email: 'info@skyviewas.com',
                        contact: 'https://skyviewas.com',
                        title: 'Drone Technology',
                        content: 'A dedicated UAV group using the latest drone technology to provide high-accuracy aerial solutions and assisting everyday living through technologically advanced innovations.',
                        bg_class: 'bg-skyview',
                        btn_text: 'SEE HOW IT WORKS',
                        brand_logo: skyview,
                        brand_right: skyview_right,
                        brand_right_class: 'skyview_right',
                        brand_logo_class: 'skyview',
                        index: '08'
                    })}}>
                        <p id='skyview' data-aos='zoom-in'>Skyview Aerial Solutions Limited</p>
                    </div>
                </div>
                {/* end list of subsidiaries */}
            </div>

            {/* footer bottom */}
            <div className='container'>
                <div className='footer-bottom'>
                    <div className='footer-bottom-left'>
                        <img id='brand-logo' src={details.brand_logo} alt='' className={`${details.brand_logo_class}`} />
                    </div>
                    <div className='footer-bottom-right'>
                        <img id='brand-right' src={details.brand_right} alt='' className={`${details.brand_right_class}`} />
                    </div>
                </div>
            </div>
            {/* end footer bottom */}
        </div>
    )
}

export default Footer;