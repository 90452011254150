import React, { useState, useEffect } from 'react'
import AOS from 'aos';
import Header from '../components/Header';
import MainCaption from '../components/MainCaption';
import Divider from '../components/Divider';
import Highlights from '../components/Highlights';
import Footer from '../components/Footer';
import Landing from '../components/Landing';
import CopyrightText from '../components/CopyrightText';


function Home () {
    AOS.init({duration: 500});
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        // eslint-disable-next-line
    }, []);

    var date = new Date()
    var timestamp = Date.parse(date);

    // load landing page
    if (loading) {
        setTimeout(() => {
        setLoading(false)
        }, 2400)
        return <Landing />;
    }                    
    
    return (
        <div data-aos='fade-in'>
            <Header />
            <MainCaption />
            <Divider />
            <Highlights />
            <Footer />
            <CopyrightText />
        </div>
    )
}

export default Home;