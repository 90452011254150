import React, { useEffect } from 'react';
import '../css/subsidiaries.css'
import Header from '../components/Header';
import kc_subs from '../images/kc_subs.svg'
import imperial_subs from '../images/imperial_subs.svg'
import gtesc_subs from '../images/gtesc_subs.svg'
import skyview_subs from '../images/skyview_subs.svg'
import gwi_subs from '../images/gwi_subs.svg'
import emperor_subs from '../images//emperor_subs.svg'
import heritage_subs from '../images/heritage_subs.svg'
import ar_subs from '../images/ar_subs.svg'
import subs_center from '../images/subs_center.svg'
import dot_subs from '../images/dot_subs.svg'
import dotted_subs from '../images/dotted_subs.svg'
import Profile from '../components/Profile';
import CopyrightText from '../components/CopyrightText';

function Subsidiaries () {
   
    useEffect(() => {
        [kc_subs, heritage_subs, emperor_subs, gtesc_subs, gwi_subs, imperial_subs, skyview_subs, ar_subs].forEach((picture) => {
            const img = new Image();
            img.src = picture;
            window[picture] = img;
        }); // force browser to prefetch images
    })
    return(
        <div className='subs-main'>
            <Header />
            <div className='subs-wrapper'>
                <div className='container subs-container'>
                    <div className='subs-content-left'>
                        {/* subsidiaries ring */}
                        <div className='subs-ring'>
                            <a href='https://kinetoconsults.com/' target='blank' rel='no referer'><div className='kc-subs' onMouseOver={(e) => e.target.classList.add('show-text')} onMouseOut={(e) => e.target.classList.remove('show-text')}>
                                <img src={kc_subs} alt='' />
                                <p>Building Design,<br />Real Estate Management<br />&amp; Consultancy</p>
                            </div></a>
                            <a href='https://imperialphoenixconsulting.com/' target='blank' rel='no referer'><div className='imperial-subs' onMouseOver={(e) => e.target.classList.add('show-text')} onMouseOut={(e) => e.target.classList.remove('show-text')}>
                                <img src={imperial_subs} alt='' />
                                <p>Human Resource &amp;<br />Management Consultancy</p>
                            </div></a>
                            <a href='https://www.gtesc.net/' target='blank' rel='no referer'><div className='gtesc-subs' onMouseOver={(e) => e.target.classList.add('show-text')} onMouseOut={(e) => e.target.classList.remove('show-text')}>
                                <img src={gtesc_subs} alt='' />
                                <p>Engineering, Construction<br />&amp; Consultancy</p>
                            </div></a>
                            <a href='https://skyviewas.com/' target='blank' rel='no referer'><div className='skyview-subs' onMouseOver={(e) => e.target.classList.add('show-text')} onMouseOut={(e) => e.target.classList.remove('show-text')}>
                                <img src={skyview_subs} alt='' />
                                <p>Aerial Technology<br />Solutions</p>
                            </div></a>
                            <a href='https://www.gwi-mining.com' target='blank' rel='no referer'><div className='gwi-subs' onMouseOver={(e) => e.target.classList.add('show-text')} onMouseOut={(e) => e.target.classList.remove('show-text')}>
                                <img src={gwi_subs} alt='' />
                                <p>Mining &amp;<br />Engineering</p>
                            </div></a>
                            <a href='https://www.emperorfarms.com/home/' target='blank' rel='no referer'><div className='emperor-subs' onMouseOver={(e) => e.target.classList.add('show-text')} onMouseOut={(e) => e.target.classList.remove('show-text')}>
                                <img src={emperor_subs} alt='' />
                                <p>Agriculture</p>
                            </div></a>
                            <a href='https://geonelholdings.com/contact-us' target='blank' rel='no referer'><div className='heritage-subs' onMouseOver={(e) => e.target.classList.add('show-text')} onMouseOut={(e) => e.target.classList.remove('show-text')}>
                                <p>Real Estate &amp;<br />Environmental Management</p>
                                <img src={heritage_subs} alt='' />
                                <img src={dot_subs} alt='' className='dot-subs' />
                                <img src={dotted_subs} alt='' className='dotted-subs' />
                            </div></a>
                            <a href='https://www.arsecuritysolutionslimited.com/' target='blank' rel='no referer'><div className='ar-subs' onMouseOver={(e) => e.target.classList.add('show-text')} onMouseOut={(e) => e.target.classList.remove('show-text')}>
                                <img src={ar_subs} alt='' />
                                <img src={dot_subs} alt='' className='dot-subs' />
                                <img src={dotted_subs} alt='' className='dotted-subs' />
                                <p>Security Technology &amp;<br />Consultancy</p>
                            </div></a>
                            <a href='https://geonelholdings.com/' target='blank' rel='no referer'><div className='subs-center'>
                                <img src={subs_center} alt='' />
                            </div></a>
                        </div>
                        {/* end subsidiaries ring */}
                    </div>
                </div>
                <Profile />
            </div>
            <CopyrightText />
        </div>
    )
}

export default Subsidiaries;