import React from 'react'
import '../css/caption.css'
import caption_video from '../video/geonel 1.mp4'
import poster from '../images/poster.svg'

function MainCaption () {
    return (
        <div className='caption-wrapper'>
            <div className='container caption-container'>
                <div className='caption-text'>
                    <h1>We are the <span style={{color: "#A0B542"}}>builders</span><br />of the future</h1>
                </div>
                <div className='caption-img'>
                    <video poster={poster} autoPlay muted loop>
                        <source src={caption_video} type="video/mp4" />
                    </video>
                </div>
            </div>
        </div>
    )
}

export default MainCaption;