import React from 'react'

function Profile() {
    return (
        <div id='profile' style={{marginTop: '10rem', backgroundColor: '#f9f9f9'}}>
            <iframe src="https://cdn.flipsnack.com/widget/v2/widget.html?hash=zpsbs0funf" width="100%" height="700" title='Company Profile' seamless="seamless" scrolling="no" frameBorder="0" allowFullScreen></iframe>
        </div>
    )
}

export default Profile;