import React from 'react';
import '../css/landing.css'
import logo from '../images/new-logo.svg'

export default function Landing () {
    return (
        <div className='landing-wrapper'>
            {/* Animated landing page */}
            <div className='landing-inner'>
                <img src={logo} alt='' />
            </div>
        </div>
    )
}