import React, { useState } from 'react'
import '../css/contact.css'
import Modal from './Modal';

function ContactForm () {
    // use function state to manage selected company to send email to
    const [target, setTarget] = useState('');

    // list of companies
    const companies = [
        {
            id: 'ar',
            name: 'AR SECURITY SOLUTIONS LIMITED',
            email: 'info@arsecuritysolutionslimited.com'
        },
        {
            id: 'emperor',
            name: 'EMPEROR INTEGRATED FARMS AND PROJECTS LIMITED',
            email: 'info@emperorfarms.com'
        },
        {
            id: 'gtesc',
            name: 'GTESC LIMITED',
            email: 'info@gtesc.net'
        },
        {
            id: 'heritage',
            name: 'HERITAGE CITY',
            email: 'info@heritagecity.com'
        },
        {
            id: 'imperial',
            name: 'IMPERIAL PHOENIX CONSULTING',
            email: 'info@imperialphoenixconsulting.com'
        },
        {
            id: 'kc',
            name: 'KINETO CONSULTS',
            email: 'info@kinetoconsults.com'
        },
        {
            id: 'skyview',
            name: 'SKYVIEW AERIAL SOLUTIONS',
            email: 'info@skyviewas.com'
        },
    ]

    // posts form input using query string parameters
    async function post(params) {
        try {
            await fetch(`https://geonelholdings.com/sendMail/index.php?email_from=${params.email}&email_to=${target}&name=${params.name}&message=${params.message}`, {
                method: "GET",
                mode: "no-cors",
            }); // wait for the form input to be posted to be server

            window.location.reload(); // reload page to reset form
        } catch(e) {
            console.log(e);
            alert('Something went wrong')
        }
        
        
    }

    function chooseTarget(e) {
        e.preventDefault();
        var form = {}; // initialize empty from object

        // assign values to the form fields
        form.name = document.getElementById('name').value;
        form.email = document.getElementById('email').value;
        form.message = document.getElementById('message').value
        post(form) // call function "post"
    }

    return (
        <div className='form-wrapper'>
            {/* Button trigger modal */}
            <button id="modal" type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter" style={{display: "none"}}>
            ...
            </button>
            {/* Modal */}
            <Modal setTarget={setTarget}>
                <div className='choose-target'>
                    <p>Send to:</p>
                    <form onSubmit={(e) => {return chooseTarget(e)}}>
                        {companies.map((item, key) => {
                            return (
                                <div key={key} className='choose-target-item'>
                                    <div><input id={item.id} type='radio' name='contact' value={item.email} onChange={(e) => {
                                        if(e.target.checked) {
                                            setTarget(e.target.value)
                                        }}
                                        
                                    } /></div>
                                    <div><label htmlFor={item.id} >{item.name}</label></div>
                                </div>
                            )
                        })} 
                        {/* use companies list to render the radio form */}
                        <div className='form-input-wrapper submit-btn-wrapper'>
                            <button type='submit' className='submit-btn'>Done</button>
                        </div>
                    </form>
                </div>
            </Modal>
            <div className='container form-container'>
                <div className='form-content-left'>
                    
                    {/* Address */}
                    <h3 className='form-content-text1'>Geonel Head Office:</h3>
                    <p className='form-content-text2'>Suite 201-203, Bahamas Plaza,<br />Plot 1080 Joseph Gomwalk Street,<br />900104, Gudu, Abuja.</p>
                    
                    {/* Get directions */}
                    <a href='https://www.google.com/maps/place/Geonel+Holdings+Limited,+Suite+201-203+Bahamas+Plaza,+Plot+1080+Joseph+Gomwalk+St,+AMAC+900104,+Abuja/@9.0005976,7.4737904,17z/data=!4m2!3m1!1s0x104e0cf3a9da188b:0xf920ce6271e2f03e' target='blank' rel='no referer'>
                        <button className='find-us-btn'>Get Directions</button>
                    </a>

                </div>
                <div className='form-content-right'>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        document.getElementById('modal').click(); // open modal
                    }} >
                        <div className='form-input-wrapper'>
                            <input id='name' type='text' placeholder='Name' required />
                        </div>
                        <div className='form-input-wrapper'>
                            <input id='email' type='email' placeholder='Your Email' required />
                        </div>
                        <div className='form-input-wrapper'>
                            <textarea id='message'  placeholder='Write us' required />
                        </div>
                        <div className='form-input-wrapper submit-btn-wrapper'>
                            <button type='submit' className='submit-btn'>Send to</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ContactForm;