import React, { useEffect } from 'react'
import AOS from 'aos'
import '../css/highlights.css'
import h_two from '../images/pivot.svg'
import commitment from '../images/commitment.svg'
import integrity from '../images/integrity.svg'
import accountability from '../images/accountability.svg'
import excellence from '../images/excellence.svg'
import innovation from '../images/innovation.svg'
import customer_focus from '../images/customer_focus.svg'
import diligence from '../images/diligence.svg'
import logo from '../images/new-logo.svg'
import core from '../images/core.svg'
import vision from '../images/vision.svg'
import mission from '../images/mission.svg'
import corp_action from '../images/corp_action.svg'

function Highlights () {
    AOS.init({duration: 500, delay: 0}); // initialize animate on scroll options
    const appearOptions = {
        threshold: 0.5,
    }
    const appearOnScroll = new IntersectionObserver(function (entries, appearOnScroll) {
        entries.forEach(entry => {
            if(!entry.isIntersecting) {
                return;
            } else {
                entry.target.classList.toggle('appear');
                appearOnScroll.unobserve(entry.target);
            }
        })
    }, appearOptions)

    // animate selected objects onload
    useEffect(() => {
        const faders = document.querySelectorAll('.fade-in') // select all elements with class "fade-in"
        faders.forEach(fader => {
            appearOnScroll.observe(fader)
        })
    }, [appearOnScroll])
    
    
    return (
        <div className='highlights-wrapper'>
            <div className='container highlights-container'>
                <div className='highlight-one'>
                    {/* core values wrapper */}
                    <div className='globe'>
                        <img src={commitment} alt='' className='commitment' />
                        <img src={integrity} alt='' className='integrity' />
                        <img src={accountability} alt='' className='accountability' />
                        <img src={excellence} alt='' className='excellence' />
                        <img src={innovation} alt='' className='innovation' />
                        <img src={customer_focus} alt='' className='customer-focus' />
                        <img src={diligence} alt='' className='diligence' />
                        <img src={core} alt='' className='core-main' />
                        <a href='/'>
                            <img src={logo} alt='' className='core-logo' />
                        </a>
                    </div>
                    {/* end core values wrapper */}
                </div>
                <div className='highlight-two'>
                    <div className='highlight-two-wrapper'>
                        <img src={h_two} alt='' className='pivot' />
                        <img src={vision} alt='' className='vision-hand fade-in'/>
                        <img src={mission} alt='' className='mission-hand'/>
                        <img src={corp_action} alt='' className='corp-action-hand fade-in'/>
                        <p className='vision' data-aos='fade-up'>To provide excellent and innovative services<br />geared towards sustainable development<br />through real-time project delivery</p>
                        <p className='mission' data-aos='flip-down'>To benchmark all the services we provide<br />by ensuring word-class solutions through innovative methods and a readiness to<br /> comply with global best standards<br /> in order to fulfil customer aspirations</p>
                        <p className='corp-action' data-aos='zoom-in'>To take up the challenges of building a global brand that efficiently responds to market needs through innovative and sincere engagement of a multi-faceted service company acting in total compliance of statutory laws and ethics governing superior business practices</p>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Highlights;