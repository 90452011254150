import React from 'react'
import '../css/modal.css'

function Modal (props) {
    const {children} = props //extract prop "children"
    
    return (
        <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {/* renders contents of prop "children" here */}
                {children}
              </div>
            </div>
          </div>
        </div>
    )
}

export default Modal;