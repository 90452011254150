import React, { useEffect } from 'react'
import '../css/header.css'
import logo from '../images/new-logo.svg'
import logo_dark from '../images/logo_dark.svg'

function Header({dark}) {
    //dark: prop passed down from Contact page

    const current = window.location.href.split('/').pop() // get the current page title
    useEffect(() => {
        if (current === "" || current === null) {
            document.getElementById('about-us').style.color = 'rgba(24,148,209, 1)'
        } else if(current !== "#map") {
            document.getElementById(current).style.color = 'rgba(24,148,209, 1)'
        }
        
    }) // use the value of "current" to set the accent color of the current page link
    return (
        <div className='header'>
            <div className='container header-container'>
                <div className='header-left'>
                    <a href='/'>
                        {/* display different images depending on time of day */}
                        {dark ? <img src={logo_dark} alt='' /> : <img src={logo} alt='' />} 
                    </a>
                </div>
                <div className='header-right'>
                    <div className='link-wrapper'>
                        <a id='about-us' href='/about-us'>About Us</a>
                    </div>
                    <div className='link-wrapper'>
                        <a id='subsidiaries' href='/subsidiaries'>Our Companies</a>
                    </div>
                    <div className='link-wrapper'>
                        <a id='contact-us' href='/contact-us'>Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;