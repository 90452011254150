import React from 'react'
import '../css/copyright_text.css'

function CopyrightText () {
    return (
        <div className='copyright-text-wrapper'>
            <p className='copyright-text'>Copyright©2019&nbsp;RC:106030 GEONEL HOLDINGS</p>
        </div>
    )
}

export default CopyrightText;